import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './SendMessage.css';
import { Primary,Secondary,Tertiary } from '../Constants/Colours';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobileScreen, faTry } from '@fortawesome/free-solid-svg-icons'
import WhiteButton from '../GlobalComponents/WhiteButton'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'

interface FormData {
  firstName: string;
  surname: string;
  email: string;
  contactNumber: string;
  service: string;
  message: string;
}


function SendMessageSection() {
  const location = useLocation();
  const {Service} = location.state || {Service: ''}
  const {form} = location.state || {form : false}
  const [thankYou, setThankYou] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [captchaValue, setCaptchaValue] = useState(null);
  const [submitDataError, setSubmitDataError] = useState(false);
  const [firstNameError,setFirstNameError] = useState(false)
  const [surnameError,setSurnameError] = useState(false)
  const [emailError,setEmailError] = useState(false)
  const [contactNumberError,setContactNumberError] = useState(false)
  const [serviceError,setServiceError] = useState(false)
  const [messageError,setMessageError] = useState(false)
  const [phoneAuth,setPhoneAuth] = useState(false)
  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const [accessId,setAccessId] = useState('')
  const [incorrectCode,setIncorrectCode] = useState(false)
  const [isActive,setIsActive] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [maxRetries,setMaxRetries] = useState(false)
  const [checkInternet,setCheckInternet] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    surname: '',
    email: '',
    contactNumber: '',
    service: '',
    message: ''
  });

  const handleCodeChange = (e :any, index:any) => {
    const newValue = e.target.value;
    if (newValue.length <= 1) {
      const updatedValues = [...values];
      updatedValues[index] = newValue;
      setValues(updatedValues);

      // Move to the next input field if the current one is filled
      if (newValue && index < 5) {
        const nextInput = document.getElementById(`input-${index + 1}`);
        if (nextInput){
        nextInput.focus();}
      }
    }
  };

  const handleCodeGen = () => {
    setIsActive(true)
    let contactPhone = formData.contactNumber
    const data = {'contactNumber':contactPhone}
    try {
      axios.post('https://aaatheatingandgas.co.uk/api/phone-auth', data, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((access) => {
      if (access.data.message === 'Too many attempts') {
        setMaxRetries(true)
      } else {
        setAccessId(access.data.id)
      }
    }).catch(() => setCheckInternet(true))
      
    } catch (error) {
      setCheckInternet(true)
      
    }
    
  }

  const handleCodeCheck = () => {
    const code = values.join('')
    const data = {'code':code, 'id':accessId}
    try {
      axios.post('https://aaatheatingandgas.co.uk/api/code-verify', data, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((valid) => valid.data.result ? sendData(formData) : setIncorrectCode(true))
    .catch(() => setCheckInternet(true)) 
    } catch (error) {
      setCheckInternet(true)
    }
    
  } 



  

  function sendData(data : any) {
    try {
      axios.post('https://aaatheatingandgas.co.uk/api/data', data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(() => RemovePhoneAuth())
    .catch(() => setSubmitDataError(true));
    } catch (error) {
     setCheckInternet(true)
    }
    
}


const RemovePhoneAuth = () => {
  const element = document.getElementById('phone-auth-container')
  if (element) {
    element.classList.add('FadeOutAnimation')
    setTimeout(() => {
      element.style.display = 'none'
      setThankYou(true)
    }, 750);
  }
}

function RemoveForm() {
  const element = document.getElementById('contact-information')
  if (element) {
    element.classList.add('FadeOutAnimation')
    setTimeout(() => {
      element.style.display = 'none'
      setPhoneAuth(true)
    }, 750);
  }
}

const ResendCode = () => {
  setIncorrectCode(false)
  handleCodeGen()
}

function AddForm() {
  setPhoneAuth(false)
  const element = document.getElementById('contact-information')
  if (element) {
    element.classList.add('FadeInAnimation')
    element.style.display = 'flex'
    
  }
}

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };



  const handleValidation = () => {
    let isValid = true;
  
    // Check for empty fields
    for (let key in formData) {
      if (!formData[key as keyof FormData]) {
        switch (key) {
          case 'firstName':
            setFirstNameError(true);
            isValid = false;
            break;
          case 'surname':
            setSurnameError(true);
            isValid = false;
            break;
          case 'email':
            setEmailError(true);
            isValid = false;
            break;
          case 'contactNumber':
            setContactNumberError(true);
            isValid = false;
            break;
          case 'service':
            setServiceError(true);
            isValid = false;
            break;
          case 'message':
            setMessageError(true);
            isValid = false;
            break;
        }
      }
    }
  
    // Additional validation for email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})*$/;
    if (!emailRegex.test(formData.email)) {
      setEmailError(true);
      isValid = false;
    }
  
    // Validation for contact number
    const phoneRegex = /^(?:\d{10}|0\d{10})$/;
    if (!phoneRegex.test(formData.contactNumber)) {
      setContactNumberError(true);
      isValid = false;
    }
    if (isValid || !formData.contactNumber.startsWith("+44")) {
      if (formData.contactNumber.startsWith('0')) {
      setFormData(prevState => ({
        ...prevState,
        'contactNumber': '+44' + formData.contactNumber.slice(1)
      }))
    } else {
      setFormData(prevState => ({
        ...prevState,
        'contactNumber': '+44' + formData.contactNumber
      }))
    }}
    return isValid;
  };

  const handleCaptchaChange = async (value : any) => {
    const data = {'token':value}
    const response = await axios.post('https://aaatheatingandgas.co.uk/api/captcha', data, {
      headers: {
          'Content-Type': 'application/json'
      }})
    if (response.data.riskAnalysis.score > 0.5) {
      setDisableSubmit(false)
    } 
  };

  const handleSubmit = () => {
    if (handleValidation()) {
      RemoveForm()
      handleCodeGen(); // Log the form data to the console if all validations pass
    } else {
      setDisableSubmit(false)
    }
      
    
      
  };

  useEffect(() => {
  if (Service != '') {
  document.getElementById('contact-information')?.scrollIntoView()
  setFormData(prevState => ({
      ...prevState,
      'service': Service
    }))
  }
  },[]) 

  useEffect(() => {
    let intervalId : any;

    // If isActive is true and seconds are greater than 0, start the timer
    if (isActive && seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
      setSeconds(60)   
    }

    // Clean up the interval on re-render
    return () => clearInterval(intervalId);
  }, [isActive, seconds]);

  useEffect(() => {
    if (form) {
      document.getElementById('contact-information')?.scrollIntoView()
    }
  },[])

  return (
    <div style={{backgroundColor:Tertiary}} className="Send-Message-Section">
      
        <div style={{backgroundColor:Primary}} className='Send-Message-Info-Container'>
        <div style={{borderColor:Secondary}} className="Background-Circle"></div>
            <div>
                  <h2>Looking for the Best Option for You?</h2>
                  <h1>Reach Out for Expert Assistance Today</h1>
                  <h3>Contact AAAT by sending a message or calling directly for immediate assistance.</h3>
            </div>
        </div>
        <div style={{backgroundColor:Tertiary}} className='Send-Message-Container'>
            {thankYou && (<>
            <div className='Thank-You-Container FadeInAnimation'>
              <FontAwesomeIcon size='7x' color={Secondary} icon={faEnvelope}/>
              <h1 >Thank You</h1>
              <h2 >We will be in touch as soon as possible</h2>

            </div>
            </>
            )
            }

            {phoneAuth && (<>
            <div id='phone-auth-container' className='Phone-Auth-Container FadeInAnimation'>
              <FontAwesomeIcon size='4x' color={Secondary} icon={faMobileScreen}/>
              <h2 >Please enter the 6 digit code sent to {formData.contactNumber} <span onClick={AddForm} style={{fontSize:'15px',color:'grey',textDecoration:'underline',cursor:'pointer'}}>Change Number</span></h2>
              <div>
              {values.map((value, index) => (
                    <input
                      key={index}
                      id={`input-${index}`}
                      type="text"
                      maxLength={1}
                      value={value}
                      style={{borderColor: incorrectCode ? 'red' : '#484c50'}}
                      onChange={(e) => handleCodeChange(e, index)}
                    />
                  ))}
              </div>
              <WhiteButton onClick={handleCodeCheck} text='Submit' height={40} width={130} darkMode={true}/>

              {incorrectCode && (
               <h3 style={{color:'red'}}>Incorrect Code</h3>
              )}
              {maxRetries && (
               <h3 style={{color:'red'}}>Max Retries Reached</h3>
              )}
              {checkInternet && (
               <h3 style={{color:'red'}}>No Internet Connection</h3>
              )}

              <button onClick={ResendCode} style={{fontSize:'15px',color:'grey',opacity:isActive ? 0.4 : 1,cursor:'pointer',backgroundColor:'transparent',border:'none',outline:'none',padding:0,margin:0,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:isActive ? 'space-between' : 'center'}} disabled={isActive} ><h3>Resend Code</h3> {isActive && (<span style={{'textDecoration':"none"}}>{seconds}s</span>)}</button>

            </div>
            </>
            )
            }
            <div className='Contact-Information-Container' id='contact-information'>
              <h1>Send Us A Message</h1>
              <div className='Names-Container'>
              <div className='Name-Input-Container'>
              <h2 style={{color: firstNameError ? 'red' : '#484c50'}}>First Name</h2> 
              <input onChange={handleChange} name='firstName' placeholder='First Name'/>
              </div>
              <div className='Name-Input-Container'>
              <h2 style={{color: surnameError ? 'red' : '#484c50'}}>Surname</h2> 
              <input onChange={handleChange} name='surname' placeholder='Surname'/>
              </div>
              </div>
              <div className='Contact-Input-Container'>
              <h2 style={{color: emailError ? 'red' : '#484c50'}}>Email</h2> 
              <input onChange={handleChange} name='email' placeholder='Email'/>
              </div>
              <div className='Contact-Input-Container'>
              <h2 style={{color: contactNumberError ? 'red' : '#484c50'}}>Contact Number</h2> 
              <div>
                <div className='Plus-Four-Four-Container'>
                  <h1>+44</h1>
                </div>
              <input onChange={handleChange} name='contactNumber' placeholder='Contact Number (UK)'/>
              </div>
              </div>
              <div className='Type-Input-Container'>
                <h2 style={{color: serviceError ? 'red' : '#484c50'}}>Service</h2>
                
                <select onChange={handleChange} defaultValue={Service} name='service' id="services">
                  <option value="" disabled>Select a Service</option>
                  <option value="Boiler Repair">Boiler Repair</option>
                  <option value="Landlord/Maintenance Certificates">Landlord/Maintenance Certificate</option>
                  <option value="Boiler Installation Services">Boiler Installation</option>
                  <option value="Smart Home System Installation">Smart Home System Installation</option>
           
                  <option value="Emergency Heating Services">Emergency Heating Services</option>
                  <option value="Radiator Installation Services">Radiator Installation Services</option>
                  <option value="Domestic Power Flush Services">Domestic Power Flush Services</option>
                  <option value="Gas Fire Service">Gas Fire Service</option>
                  <option value="Home Interface Unit Repair">Heat Interface Unit Repair</option>
                  <option value="Unvented Cylinder Installations">Unvented Cylinder Installation</option>
                  <option value="Home Interface Unit Installation">Heat Interface Unit Installation</option>
                  <option value="Normal Cylinder Repair">Cylinder Repair</option>
                  <option value="Unvented Cylinder Repair">Unvented Cylinder Repair</option>
                  <option value="Normal Cylinder Installation">Cylinder Installation</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className='Message-Input-Container'>
              <h2 style={{color: messageError ? 'red' : '#484c50'}}>Message</h2>
                <textarea onChange={handleChange} name='message' placeholder='Your Message'/>
              </div>
              <ReCAPTCHA
          sitekey="6LfHnBYqAAAAAFutddHEDSeXpIPP8iDOo6Jwtgo3" 
          onChange={handleCaptchaChange} 
        />
              <WhiteButton disabled={disableSubmit} onClick={handleSubmit} text='Submit' height={50} width={150} darkMode={true} arrow={true}/>
            </div>
            
        </div>
    </div>
  );
}

export default SendMessageSection;
